import React from 'react';

const degree2angle = (d) => d / 360 * 2 * Math.PI;
const angle2degree = (a) => a / 2 / Math.PI * 360;

/* 等角八邊形 */
const EightGonWithLines = ({ cx, cy, r, textArray, dr = 0.85, ...rest }) => {
    const polyNum = 8;
    const AngleEachPie = degree2angle(360 / polyNum / 2);
    const H = r * Math.cos(AngleEachPie);
    const S = r * Math.sin(AngleEachPie);
    return (
        <g>
            <polygon
                {...rest}
                points={
                    [
                        [cx - S, cy - H].join(','),
                        [cx + S, cy - H].join(','),
                        [cx, cy].join(','),
                        [cx + S, cy - H].join(','),
                        [cx + H, cy - S].join(','),
                        [cx, cy].join(','),
                        [cx + H, cy - S].join(','),
                        [cx + H, cy + S].join(','),
                        [cx, cy].join(','),
                        [cx + H, cy + S].join(','),
                        [cx + S, cy + H].join(','),
                        [cx, cy].join(','),
                        [cx + S, cy + H].join(','),
                        [cx - S, cy + H].join(','),
                        [cx, cy].join(','),
                        [cx - S, cy + H].join(','),
                        [cx - H, cy + S].join(','),
                        [cx, cy].join(','),
                        [cx - H, cy + S].join(','),
                        [cx - H, cy - S].join(','),
                        [cx, cy].join(','),
                        [cx - H, cy - S].join(','),
                        [cx - S, cy - H].join(','),
                        [cx, cy].join(','),
                    ].join(' ')
                }
            >
            </polygon>
        </g>
    );
};

const STextByDegrees = ({
    cx = 0,
    cy = 0,
    dr = 1,
    fontSize = 12,
    degrees = [],
    colIdx = 0,
    colNum = 1,
    colFollowAngle = false,
    colWider = true,
    textArray = [],
    colorList = [],
    fill,
    ...rest
}) => {
    return (
        degrees.map((degree, idx, list) => {
            const nextDegree = (idx + 1 >= list.length) ? 360 - list[0] : list[idx + 1];
            const totalDegree = nextDegree - degree;
            const begDegree = degree - totalDegree / 2;
            const eachColDegree = totalDegree / (colNum + 1);
            const offsetDegree = begDegree + eachColDegree * (colIdx + 1);
            //const offsetDegree = degree + (colIdx - colNum / 2 ) * eachColDegree;
            const angle = degree2angle(offsetDegree);
            const text = Array.isArray(textArray) && textArray[idx];
            const color = colorList.find(c => c.nameList && c.nameList.includes(text))?.color;
            const finalColor = (text && color) ? color : fill;
            return (
                <SText
                    {...rest}
                    key={`STextByDegrees-${idx}`}
                    cx={cx + dr * Math.cos(angle)}
                    cy={cy + dr * Math.sin(angle)}
                    rotate={colFollowAngle ? offsetDegree : degree}
                    fontSize={fontSize}
                    fill={finalColor}
                    text={text}
                ></SText>
            );
        })
    );
};

const SText8 = ({ degrees, ...rest }) => {
    return (
        <STextByDegrees
            degrees={[0, 45, 90, 135, 180, 225, 270, 315]}
            {...rest}
        ></STextByDegrees>
    );
};

const SText12 = ({ degrees, ...rest }) => {
    return (
        <STextByDegrees
            degrees={[0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330]}
            colFollowAngle={true}
            {...rest}
        ></STextByDegrees>
    );
};

const SText24 = ({ degrees, ...rest }) => {
    return (
        <STextByDegrees
            degrees={[0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240, 255, 270, 285, 300, 315, 330, 345]}
            colFollowAngle={true}
            {...rest}
        ></STextByDegrees>
    );
};

const EightGon = ({ cx, cy, r, ...rest }) => {
    const polyNum = 8;
    const AngleEachPie = degree2angle(360 / polyNum / 2);
    const H = r * Math.cos(AngleEachPie);
    const S = r * Math.sin(AngleEachPie);
    return (
        <polygon
            points={
                [
                    [cx - S, cy - H].join(','),
                    [cx + S, cy - H].join(','),
                    [cx + H, cy - S].join(','),
                    [cx + H, cy + S].join(','),
                    [cx + S, cy + H].join(','),
                    [cx - S, cy + H].join(','),
                    [cx - H, cy + S].join(','),
                    [cx - H, cy - S].join(','),
                ].join(' ')
            }
            {...rest}
        >
        </polygon>
    );
};

const InnerCircle = ({ cx, cy, r, text, fontSize = 18, ...rest }) => {
    const multiline = text ? `${text}`.split('\n') : [];
    return (
        <g>
            <circle cx={cx} cy={cy} r={r} {...rest} />
            {multiline.map((l, idx) => (
                <text
                    key={`tspan-${idx}`}
                    x={cx}
                    y={cy}
                    dy={idx * fontSize}
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fontSize={fontSize}
                >{l}</text>
            ))}
        </g>
    );
};

const SText = ({
    cx = 100,
    cy = 100,
    width = 100,
    height = 200,
    text,
    angle = 0,
    rotate,
    fontSize,
    vertical,
    fill,
    roundingBorder = false,
    ...rest
}) => {
    const degree = (rotate || angle2degree(angle)) - 90;
    const textLength = (text && `${text}`.replace(/(?:\\[rn]|[\r\n]+)+/g, '').length) || 0;
    const FontSize = textLength > 2 ? fontSize - 3 : fontSize;
    const Transform = `translate(${width / 2},${height / 2}) rotate(${degree})`;
    //const Rounding = [{short: 0.8, long: 1.3}, {short: 0.8, long: 1.3}][];
    return (
        <svg x={cx - width / 2} y={cy - height / 2} width={width} height={height}>
            {/*<rect width={width} height={height} stroke="red" stroke-width="3" fill="white"></rect>*/}
            <text
                {...rest}
                fill={fill}
                fontSize={FontSize}
                transform={Transform}
                writingMode={vertical ? "tb" : undefined}
                alignmentBaseline={vertical ? undefined : "middle"}
                textAnchor="middle"
            >{text}</text>
            {roundingBorder && (
                <ellipse
                    {...rest}
                    x={cx - width / 2} y={cy - height / 2}
                    rx={0.75 * FontSize * (vertical ? 1 : (textLength * 1.1))}
                    ry={0.75 * FontSize * (vertical ? (textLength) : 1)}
                    fill="none"
                    stroke={fill}
                    strokeWidth={1}
                    transform={Transform}
                />
            )}
        </svg>
    );
};

const CirlceWithLines = ({ cx, cy, r, textArray, cutNum = 6, angleOffset = 0, ...rest }) => {
    const AngleEachPie = Math.PI / cutNum;
    return (
        <g>
            <circle {...rest} cx={cx} cy={cy} r={r} />
            {Array.from(Array(cutNum)).map((_, idx) => {
                const StartAngle = (idx - angleOffset) * AngleEachPie;
                return (
                    <g key={`CirlceWithLines-${idx}`}>
                        <line
                            {...rest}
                            x1={cx - r * Math.cos(StartAngle)}
                            y1={cy - r * Math.sin(StartAngle)}
                            x2={cx + r * Math.cos(StartAngle)}
                            y2={cy + r * Math.sin(StartAngle)}
                        />
                    </g>
                );
            })}
        </g>
    );
};

const SvgCanvas = React.forwardRef(({ dataUrl, strokeWidth = 1, stroke = "red" }, ref) => {
    const Size = 500;
    const MaxRadius = (Size / 2 - strokeWidth);
    const CenterXY = { cx: Size / 2, cy: Size / 2 };

    const [data, setData] = React.useState(null);

    const [textCenter, setCenter] = React.useState(null);

    const [text8_1, set8_1] = React.useState(null);

    const [text8_21, set8_21] = React.useState(null);
    const [text8_2, set8_2] = React.useState(null);
    const [text8_23, set8_23] = React.useState(null);

    const [text8_31, set8_31] = React.useState(null);
    const [text8_3, set8_3] = React.useState(null);
    const [text8_33, set8_33] = React.useState(null);

    const [text8_41, set8_41] = React.useState(null);
    const [text8_43, set8_43] = React.useState(null);

    const [textC_1, setC_1] = React.useState(null);

    const [textC_21, setC_21] = React.useState(null);
    const [textC_22, setC_22] = React.useState(null);
    const [textC_23, setC_23] = React.useState(null);
    const [textC_24, setC_24] = React.useState(null);

    const [textC_31, setC_31] = React.useState(null);
    const [textC_32, setC_32] = React.useState(null);
    const [textC_33, setC_33] = React.useState(null);
    const [textC_34, setC_34] = React.useState(null);


    React.useEffect(() => {
        fetch(dataUrl)
            .then(res => res.json())
            .then(setData)
            .catch(console.error);
    }, [dataUrl]);

    React.useEffect(() => {
        if (Array.isArray(data)) {
            setCenter(data[0]['中心']);
            set8_1(data.map(e => e['八卦1']));

            set8_21(data.map(e => e['八卦2-1']));
            set8_2(data.map(e => e['八卦2']));
            set8_23(data.map(e => e['八卦2-3']));

            set8_31(data.map(e => e['八卦3-1']));
            set8_3(data.map(e => e['八卦3']));
            set8_33(data.map(e => e['八卦3-3']));

            set8_41(data.map(e => e['八卦4-1']));
            set8_43(data.map(e => e['八卦4-3']));

            setC_1(data.map(e => e['環1']));

            setC_21(data.map(e => e['環2-1']));
            setC_22(data.map(e => e['環2-2']));
            setC_23(data.map(e => e['環2-3']));
            setC_24(data.map(e => e['環2-2'] === '太沖' ? '天馬' : ''));

            setC_31(data.map(e => e['環3-1']));
            setC_32(data.map(e => e['環3-2']));
            setC_33(data.map(e => e['環3-3']));
            setC_34(data.map(e => e['環3-4']));
        }
        else if (data) {
            if (Array.isArray(data.values)) {
                const header = data.values[0];
                const rowData = data.values.slice(1);
                const row2obj = (r) => r.reduce((pre, val, idx) => {
                    const attr = header[idx];
                    if (attr) pre[attr] = val;
                    return pre;
                }, {});
                const objData = rowData.map(row2obj);
                // console.debug(objData);
                setData(objData);
            }
        }
    }, [data]);

    const CircleRadius = [0.63 * MaxRadius, 0.7 * MaxRadius, 0.85 * MaxRadius, MaxRadius];
    const TextRadiusOnCircles = [
        null,
        (CircleRadius[0] + CircleRadius[1]) / 2,
        (CircleRadius[1] + CircleRadius[2]) / 2,
        (CircleRadius[2] + CircleRadius[3]) / 2,
    ];

    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            height="100%"
            width="100%"
            viewBox="0 0 500 500"
            preserveAspectRatio="xMidYMin slice"
        >
            <CirlceWithLines {...CenterXY} r={CircleRadius[3]} stroke={stroke} strokeWidth={strokeWidth} fill="white" cutNum={12} angleOffset={0.5} />
            <SText24 {...CenterXY} dr={TextRadiusOnCircles[3]} textArray={textC_31} colIdx={0} colNum={4} vertical="true" />
            <SText24 {...CenterXY} dr={TextRadiusOnCircles[3]} textArray={textC_32} colIdx={1} colNum={4} vertical="true" />
            <SText24 {...CenterXY} dr={TextRadiusOnCircles[3]} textArray={textC_33} colIdx={2} colNum={4} vertical="true" />
            <SText24 {...CenterXY} dr={TextRadiusOnCircles[3]} textArray={textC_34} colIdx={3} colNum={4} vertical="true" />

            <CirlceWithLines {...CenterXY} r={CircleRadius[2]} stroke={stroke} strokeWidth={strokeWidth} fill="white" cutNum={6} angleOffset={0.5} />
            <SText12 {...CenterXY} dr={TextRadiusOnCircles[2]} textArray={textC_21} colIdx={0} colNum={4} vertical="true" colorList={[{ color: 'red', nameList: ['除', '定', '危', '開'] }]} roundingBorder={true} />
            <SText12 {...CenterXY} dr={TextRadiusOnCircles[2]} textArray={textC_22} colIdx={1} colNum={4} vertical="true" colorList={[{ color: 'red', nameList: ['太沖', '小吉', '從魁'] }]} roundingBorder={true} />
            <SText12 {...CenterXY} dr={TextRadiusOnCircles[2]} textArray={textC_23} colIdx={2} colNum={4} vertical="true" colorList={[{ color: 'red', nameList: ['太陰', '六合', '太常'] }]} roundingBorder={true} />
            <SText12 {...CenterXY} dr={TextRadiusOnCircles[2]} textArray={textC_24} colIdx={3} colNum={4} vertical="true" fill="red" />
            {/* 第四欄位只有當第二欄位是太沖時才會多寫天馬在第四欄位,所以永遠設紅色 */}

            <CirlceWithLines {...CenterXY} r={CircleRadius[1]} stroke={stroke} strokeWidth={strokeWidth} fill="white" cutNum={12} angleOffset={0.5} />
            <SText24 {...CenterXY} dr={TextRadiusOnCircles[1]} textArray={textC_1} vertical="true" />

            <circle {...CenterXY} r={CircleRadius[0]} stroke={stroke} strokeWidth={strokeWidth} fill="white" />

            <EightGonWithLines {...CenterXY} r={0.6 * MaxRadius} stroke={stroke} strokeWidth={strokeWidth} fill="none" />
            <SText8 {...CenterXY} dr={0.5 * MaxRadius} textArray={text8_41} colIdx={0} colNum={4} vertical="true" />
            <SText8 {...CenterXY} dr={0.493 * MaxRadius} textArray={['天柱', '天心', '天蓬', '天任', '天沖', '天輔', '天英', '天芮']} colIdx={1} colNum={4} vertical="true" fill="blue" />
            <SText8 {...CenterXY} dr={0.493 * MaxRadius} textArray={text8_43} colIdx={2} colNum={4} vertical="true" />
            <SText8 {...CenterXY} dr={0.5 * MaxRadius} textArray={['驚門', '開門', '休門', '生門', '傷門', '杜門', '景門', '死門']} colIdx={3} colNum={4} vertical="true" fill="blue" />

            <EightGonWithLines {...CenterXY} r={0.46 * MaxRadius} stroke={stroke} strokeWidth={strokeWidth} fill="none" />
            <SText8 {...CenterXY} dr={0.4 * MaxRadius} textArray={text8_31} colIdx={0} colNum={3} />
            <SText8 {...CenterXY} dr={0.396 * MaxRadius} textArray={text8_3} colIdx={1} colNum={3} colorList={[{ color: 'red', nameList: ['生門', '休門', '開門'] }]} />
            <SText8 {...CenterXY} dr={0.4 * MaxRadius} textArray={text8_33} colIdx={2} colNum={3} />

            <EightGonWithLines {...CenterXY} r={0.38 * MaxRadius} stroke={stroke} strokeWidth={strokeWidth} fill="none" />
            <SText8 {...CenterXY} dr={0.326 * MaxRadius} textArray={text8_21} colIdx={0} colNum={4} colorList={[{ color: 'red', nameList: ['乙', '丙', '丁'] }]} />
            <SText8 {...CenterXY} dr={0.315 * MaxRadius} textArray={text8_2} colIdx={1.5} colNum={4} />
            <SText8 {...CenterXY} dr={0.326 * MaxRadius} textArray={text8_23} colIdx={3} colNum={4} colorList={[{ color: 'red', nameList: ['乙', '丙', '丁'] }]} />

            <EightGonWithLines {...CenterXY} r={0.29 * MaxRadius} stroke={stroke} strokeWidth={strokeWidth} fill="none" />
            <SText8 {...CenterXY} dr={0.236 * MaxRadius} textArray={text8_1} colorList={[{ color: 'red', nameList: ['九天', '九地', '六合', '太陰'] }]} />

            <EightGon {...CenterXY} r={0.2 * MaxRadius} stroke={stroke} strokeWidth={strokeWidth} fill="white" />
            <InnerCircle {...CenterXY} r={0.15 * MaxRadius} text={textCenter} stroke={stroke} strokeWidth={strokeWidth} fill="white" />
        </svg>
    );
});

export default SvgCanvas;