import React, { useRef, useState } from 'react';
import './App.css';
import SvgCanvas from './SvgCanvas';
import { saveSvgAsPng } from 'save-svg-as-png';

function App() {
  const refSvg = useRef(null);

  const [downloadUrl, setUrl] = useState(null);

  React.useEffect(() => {
    return () => {
      if (downloadUrl) URL.revokeObjectURL(downloadUrl);
    };
  }, [downloadUrl]);

  const savePNG = () => {
    saveSvgAsPng(refSvg.current, '八卦圓盤.png', { scale: 2 });
  };

  const generateSVGurl = () => {
    setUrl(null);

    // https://stackoverflow.com/questions/23218174

    const serializer = new XMLSerializer();
    let source = serializer.serializeToString(refSvg.current);

    //add name spaces.
    if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }

    //add xml declaration
    source = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>\r\n${source}`;

    const blob = new Blob([source], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    setUrl(url);
  };

  return (
    <div className="App">
      <p>
        如果要改變圓盤上的文字，請點
        <a
          href="https://docs.google.com/spreadsheets/d/1NRNgRmkamX6hFyusAsvxIrv_bDRaLree-VXG_Jlzs74"
          target="_blank"
          rel="noopener noreferrer"
        >這裡進入</a>
        修改。
        <button onClick={generateSVGurl}>下載svg</button>
        <button onClick={savePNG}>下載png</button>
      </p>
      {downloadUrl && (
        <a href={downloadUrl} download="八卦圓盤.svg">下載連結</a>
      )}
      <header className="App-header">
        <SvgCanvas
          ref={refSvg}
          dataUrl="https://sheets.googleapis.com/v4/spreadsheets/1NRNgRmkamX6hFyusAsvxIrv_bDRaLree-VXG_Jlzs74/values/Eight!A1:Q25?key=AIzaSyDOK43xu6GvJCLRsbgBUv421rSmH-GabKg"
        ></SvgCanvas>
      </header>

    </div>
  );
}

export default App;
